import React from 'react'
import { Card, Container, Col, Row } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Title from '../title/title'
import Link from '../link'


const MoreRooms = ({ currentRoomTitle }) => {
    const { t, i18n } = useTranslation('room')
    const data = useStaticQuery(graphql`
        {
            allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
                edges {
                    node {
                        id
                        excerpt(pruneLength: 250)
                        frontmatter {
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            title
                            key
                            shortDesc
                            image {
                                childImageSharp {
                                    gatsbyImageData(layout: FULL_WIDTH)
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const rooms = data.allMarkdownRemark.edges
        .filter(r => r.node.frontmatter.key === i18n.language)
        .filter(r => r.node.frontmatter.title !== currentRoomTitle)
    return (
        <Container className="mb-4">
            <Title title={t('moreTitle')} center />
            <Row>
                {rooms.map(room => (
                    <Col key={room.node.id} md={6}>
                        <Fade bottom duration={500} delay={400} distance="30px">
                            <Card>
                                <GatsbyImage
                                    image={
                                        room.node.frontmatter.image.childImageSharp.gatsbyImageData
                                    }
                                    alt={room.node.frontmatter.title}
                                />
                                <Card.Body>
                                    <Card.Title>{room.node.frontmatter.title}</Card.Title>
                                    <Card.Text>{room.node.frontmatter.shortDesc}</Card.Text>
                                    <Link to={room.node.frontmatter.slug}>
                                        <button type="button" className="cta-btn cta-btn--hero">
                                            <span> View Details</span>
                                        </button>
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Fade>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default MoreRooms

MoreRooms.propTypes = {
    currentRoomTitle: PropTypes.string.isRequired,
}
