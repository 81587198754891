import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useTranslation } from 'react-i18next'
import Overlay from '../overlay/overlay'

const Hero = ({ backgroundImage, title }) => {
    const { t } = useTranslation('room')
    const [isDesktop, setIsDesktop] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true)
            setIsMobile(false)
        } else {
            setIsMobile(true)
            setIsDesktop(false)
        }
    }, [])

    return (
        <section id="hero" className="jumbotron">
            <GatsbyImage image={backgroundImage} alt={title} style={{ maxHeight: '600px' }} />
            <Overlay />
            <Container fluid>
                <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance="30px"
                >
                    <h1 className="hero-title">
                        {t('heroTitle')}
                        <span style={{ textTransform: 'capitalize' }}>{title}</span>
                        <br />
                    </h1>
                </Fade>
            </Container>
        </section>
    )
}

export default Hero

Hero.propTypes = {
    backgroundImage: PropTypes.object,
    title: PropTypes.string,
}
