import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import HotelIcon from '@material-ui/icons/Hotel'
import PropTypes from 'prop-types'

const RoomAmenities = ({ facilities }) => (
    <section id="amenities">
        <Container fluid>
            <Fade bottom duration={500} delay={400} distance="30px">
                <Container>
                    <div className="room-amenities-wrapper">
                        <Row>
                            <Col lg={4} sm={12}>
                                <div className="room-amenities-wrapper__icon">
                                    <HotelIcon fontSize="large" />
                                    <h4>Facilities & Services</h4>
                                </div>
                            </Col>
                            <Col lg={3} sm={12} className="mb-4">
                                {facilities
                                    .slice(0, Math.round(facilities.length / 2))
                                    .map(facility => (
                                        <div
                                            key={facility}
                                            className="room-amenities-wrapper__amenities"
                                        >
                                            <ChevronRightIcon fontSize="small" />
                                            <div style={{ fontSize: '0.9rem' }}>{facility}</div>
                                        </div>
                                    ))}
                            </Col>
                            <Col lg={3} sm={12}>
                                {facilities
                                    .slice(Math.round(facilities.length / 2), facilities.length)
                                    .map(facility => (
                                        <div
                                            key={facility}
                                            className="room-amenities-wrapper__amenities"
                                        >
                                            <ChevronRightIcon fontSize="small" />
                                            <div style={{ fontSize: '0.9rem' }}>{facility}</div>
                                        </div>
                                    ))}
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fade>
        </Container>
    </section>
)

export default RoomAmenities

RoomAmenities.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    facilities: PropTypes.array.isRequired,
}
