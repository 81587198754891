import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero/hero'
import RoomDescription from '../components/roomDetail/roomDescriptions'
import RoomAmenities from '../components/roomDetail/roomAmenities'
import RoomGallery from '../components/roomDetail/roomGallery'
import MoreRooms from '../components/roomDetail/moreRooms'

const featuredImage = data => data.markdownRemark.frontmatter.image.childImageSharp.gatsbyImageData
function Template({ data }) {
    // this data prop will be injected by the GraphQL query below.
    const { frontmatter, html } = data.markdownRemark // data.markdownRemark holds your post data
    const imagePath = getSrc(featuredImage(data))

    return (
        <Layout darkHeader={false}>
            <SEO
                title={frontmatter.title}
                description={frontmatter.description}
                image={imagePath}
                alreadyTranslated
            />

            <Hero
                backgroundImage={featuredImage(data)}
                title={frontmatter.title}
                height={600}
                aspectRatio={16 / 9}
            />
            <RoomDescription description={html} sq={frontmatter.sq} guests={frontmatter.guests} />
            <RoomAmenities facilities={frontmatter.facilities} />
            <RoomGallery gallery={frontmatter.gallery} />
            <MoreRooms currentRoomTitle={frontmatter.title} />
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($originalPath: String!, $lang: String!) {
        markdownRemark(frontmatter: { slug: { eq: $originalPath }, key: { eq: $lang } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                template
                key
                gallery
                facilities
                slug
                sq
                guests
                image {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH, webpOptions: { quality: 50 })
                    }
                }
            }
        }
    }
`

Template.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                date: PropTypes.string,
                gallery: PropTypes.arrayOf(PropTypes.string),
                facilities: PropTypes.arrayOf(PropTypes.string),
                sq: PropTypes.string,
                guests: PropTypes.string,
            }),
            html: PropTypes.string,
        }),
    }),
}

Template.defaultProps = {
    data: {},
}

export default Template
