/* eslint-disable react/require-default-props */
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useTranslation } from 'react-i18next'
import KingBedIcon from '@material-ui/icons/KingBed'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BathtubIcon from '@material-ui/icons/Bathtub'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'

const RoomDescription = ({ description, sq, guests }) => {
    const { t } = useTranslation('room')
    return (
        <Container>
            <Fade bottom duration={500} delay={400} distance="30px">
                <div className="page-content-wrapper">
                    <Row>
                        <Col lg={9} sm={12}>
                            <h2 className="page-content-wrapper__title">{t('descriptionTitle')}</h2>
                            <div className="page-content-wrapper__body">
                                <Markdown rehypePlugins={[rehypeRaw]}>{description}</Markdown>
                            </div>
                        </Col>
                        <Col lg={3} sm={12}>
                            <div className="page-content-wrapper__room">
                                {/* <div className="page-content-wrapper__room-price">
                                    <div>FROM</div>
                                    <h2>249 &euro;</h2>
                                </div> */}
                                <div className="page-content-wrapper__room-feature">
                                    <MeetingRoomIcon />
                                    <p>{sq} {t('sq')}</p>
                                </div>
                                <div className="page-content-wrapper__room-feature">
                                    <KingBedIcon />
                                    <p>{t('bed')}</p>
                                </div>
                                <div className="page-content-wrapper__room-feature">
                                    <PeopleAltIcon />
                                    <p>{guests} {t('guests')}</p>
                                </div>
                                <div className="page-content-wrapper__room-feature">
                                    <BathtubIcon />
                                    <p>{t('bathroom')}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fade>
        </Container>
    )
}

export default RoomDescription

RoomDescription.propTypes = {
    description: PropTypes.string,
}
